import React from "react"
import Edit from "./edit"

const Index = (props: { id: number }) => {
  const { id } = props
  return (
    <>
      owf wel {id} How do you do
    </>
  )
}

export default Index
